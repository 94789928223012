import React from 'react';
import './Project.css';
import { projects } from '../../portfolio';
import ProjectCard from '../ProjectCard/ProjectCard';
import emoji from 'react-easy-emoji';

function Project() {
    return (
        <section className="section--project" id="project">
            <div className="inner">

                <div className="project-title">
                    <p>{projects.title}</p>
                </div>
                <div className="project-subtitle">
                    <p>{projects.subTitle}</p>
                </div>
                <div className="project-content">
                    <text>
			✔ Внедрение SIP телефонии с нуля на своих мощностях(не облако). Около 1000 контактов.<br />
			<br />
			✔ Многочисленные проекты модернизации(обновление серверов и сетевого оборудования из коробки в строй).<br />
			<br />
			✔ Проекты по открытию магазинов(более 10 штук), дополнительных офисов и даже специализированной лаборатории.<br />
			<br />
			✔ Расширение склада в 2 раза без остановки его работы.<br />
			<br />
			✔ Внедрение DLP системы<br />
			<br />
			✔ Внедрение регламентов отдела, KPI, портала для обучения и адаптации.<br />
			<br />
			✔ Внедрение системы учета заявок и SLA.<br />
		     </text>
                </div>
            </div>
        </section>
    );
}
export default Project;
